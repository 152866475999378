<template>
	<div class="mypage_content_wrap">
		<h2>나의 재생 리스트</h2>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<select v-model="selected" name="" class="order_select2">
						<option selected value="first">최근 재생순</option>
						<option value="second">최다 재생순</option>
					</select>
					<div @click="checked_valid = true" v-if="checked_valid === false" class="edit d_block">편집</div>
					<div v-if="checked_valid === true" class="completion">
						<ul>
							<li v-if="all_ck_valid === false" @click="check_all()">전체선택</li>
							<li v-if="all_ck_valid === true" @click="check_all_cancle()">선택해제</li>
							<li @click="checked_valid = false">편집취소</li>
							<li @click="check_delete()">삭제완료</li>
						</ul>
					</div>
				</div>
				<div v-for="(item, index) in play_list" :key="index" class="mypage_list_box">
					<input v-if="checked_valid === true" id="play_check_test" type="checkbox" />
					<div class="mypage_list_img">
						<img alt="" :src="`${$image_host}/contents/${item.thumb_path}`" />
					</div>
					<ul class="tit_wrap d_none_533">
						<li class="bold">제목</li>
						<!-- <li class="bold">사용기한</li> -->
						<li class="bold">최근열람일자</li>
						<li @click="$router.push(`/detail/${item.opus_no}`)" class="btn">바로보기</li>
					</ul>
					<ul class="info_wrap">
						<li>{{ item.opus_title }}</li>
						<!-- <li>{{ item.use_date }}~{{ item.rent_end_date }}</li> -->
						<li>{{ item.last_watch_date }}</li>
						<li @click="$router.push(`/detail/${item.opus_no}`)" class="btn">바로보기</li>
						<!-- <button
							style="cursor: default"
							v-if="item.w_type === '대여'"
							type="button"
							class="circle_basic_btn circle_green"
						>
							{{ item.w_type }}
						</button>
						<button
							style="cursor: default"
							v-if="item.w_type === '무료'"
							type="button"
							class="circle_basic_btn circle_blue"
						>
							{{ item.w_type }}
						</button>
						<button
							style="cursor: default"
							v-if="item.w_type === '소장'"
							type="button"
							class="circle_basic_btn circle_yellow"
						>
							{{ item.w_type }}
						</button>
						<button
							style="cursor: default"
							v-if="item.w_type === '정기권'"
							type="button"
							class="circle_basic_btn circle_red"
						>
							{{ item.w_type }}
						</button> -->
					</ul>
				</div>
				<div v-if="jwt.getToken('id_token')" class="pagenation_wrap">
					<ul class="pagenation">
						<Pagination
							v-if="play_list.length > 0"
							@changePage="page_active"
							:page="page"
							:totalPage="totalPage"
						></Pagination>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Pagination from '@/shared-components/Pagination.vue';
import ajax from '@/api/api-manager';
import jwt from '@/api/LocalStorage';

const selected = ref('first');
const checked_valid = ref(false);
const all_ck_valid = ref(false);
const play_list = ref([]);
const page = ref(null);
const totalPage = ref(null);
const current_btn_valid = ref(1); // 현재 페이지?

onMounted(() => {
	if (jwt.getToken('id_token')) {
		play_list_paging();
	}
});

// 전체 선택
const check_all = () => {
	const checked_data = document.querySelectorAll('#play_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (p.checked = true), (all_ck_valid.value = true);
	});
};

// 전체 선택 해제
const check_all_cancle = () => {
	const checked_data = document.querySelectorAll('#play_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (all_ck_valid.value = false), (p.checked = false);
	});
};

// 항목 선택
const check_delete = () => {
	const checked_data = document.querySelectorAll('#play_check_test');
	if (confirm('선택하신 작품들을 삭제하시겠습니까?')) {
		Array.from(checked_data).map((p, idx) => {
			if (p.checked) {
				delete_like_list(idx);
			}
		});
	}
};

// 선택한 항목 삭제
const delete_like_list = (index) => {
	const form = new FormData();
	form.append('seq', play_list.value[index].watch_seq);

	ajax('mypage/delete_play_list', form).then((res) => {
		play_list_paging();
		const checked_data = document.querySelectorAll('#play_check_test');
		Array.from(checked_data).map((p, idx) => {
			return (p.checked = false);
		});
	});
};

// 선택 항목 편집
// const change_select = () => {
watch(selected, (newData) => {
	play_list_paging();
	current_btn_valid.value = 1;
});

// };

const play_list_paging = () => {
	const parsing = () => {
		if (selected.value === 'first') {
			return 'R';
		} else if (selected.value === 'second') {
			return 'M';
		}
	};
	const form = new FormData();
	form.append('order', parsing());
	form.append('page', current_btn_valid.value);
	ajax('mypage/play_list', form).then((res) => {
		console.log(res);
		play_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};
const page_active = (event) => {
	current_btn_valid.value = event;
	play_list_paging();
};
</script>
